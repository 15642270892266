@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.candidate-search {
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__section {
    &-categories,
    &-tools,
    &-skills,
    &-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 6.48vmin;
    }

    &-bottom {
      margin-bottom: 0;
      padding-bottom: 20.74vmin;

      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        padding-bottom: 45px;
      }
    }

    &-title {
      font-size: 1.85vmin;
      line-height: 3.24vmin;
      font-weight: 600;
      text-align: center;
      margin-bottom: 2.22vmin;

      @media (max-width: $breakpoint-md) {
        font-size: 14px;
        line-height: 120%;
        margin: 0 25px 20px;
        font-weight: 700;
      }
    }
  }

  &__select {
    min-height: 5.09vmin;
    margin-bottom: 1.11vmin;

    @media (max-width: $breakpoint-md) {
      height: 42px;
      min-height: 42px;
      width: 100%;
      margin-bottom: 18px;
    }
  }

  &__skills-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 80%;

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
  }

  &__slider {
    width: 30%;
    margin-bottom: 7.65vmin;

    @media (max-width: $breakpoint-md) {
      width: 50%;
      margin-bottom: 40px;
    }
  }

  &__reset {
    font-size: 1.48vmin;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 4.07vmin;
    transition-duration: 0.3s;

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
      color: $color-secondary;
      transition-duration: 0.3s;
    }
  }

  &__section-title {
    &.-bottom {
      @media (max-width: $breakpoint-md) {
        display: none;
      }
    }
  }

  &__phone {
    color: $color-primary;
    font-size: 2.96vmin;
    letter-spacing: 0.10vmin;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 3.43vmin;

    @media (max-width: $breakpoint-md) {
      display: none;
    }
  }

  &__button-to-top {
    margin-bottom: 2.29vmin;

    @media (max-width: $breakpoint-md) {
      display: none;
    }
  }

  &__text-to-top {
    color: $color-grey-darker;
    font-size: 1.48vmin;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    transition-duration: 0.3s;

    @media (max-width: $breakpoint-md) {
      display: none;
    }

    &:hover {
      cursor: pointer;
      color: $color-secondary;
      transition-duration: 0.3s;
    }
  }
}