@import "../../../../../assets/style/colors";

.video-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $color-white;
  height: 5.09vmin;
  border-radius: 2.87vmin;
  padding: 0 2.46vmin;
  transition-duration: 0.2s;

  &__icon {
    background: $color-secondary;
    height: 3.05vmin;
    width: 2.54vmin;
    clip-path: polygon(0 0, 100% 50%, 100% 50%, 0 100%);
    margin-right: 1.84vmin;
  }

  &__label {
    font-size: 1.23vmin;
    font-weight: 700;
    text-transform: uppercase;
  }

  &:hover {
    cursor: pointer;
    background: $color-grey-lightest;
    transition-duration: 0.2s;
  }
}
