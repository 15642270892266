@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.full-screen-prompt-modal {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9000;

  &.-hidden {
    display: none;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__backdrop {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-primary;
    width: 100%;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    color: $color-white;
    font-size: 4.35vmin;
    line-height: 5.28vmin;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1.85vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 40px;
      line-height: 120%;
      margin-bottom: 30px;
      padding: 0 45px;
    }
    @media (max-width: $breakpoint-md) {
      font-size: 30px;
      line-height: 120%;
      margin-bottom: 25px;
      padding: 0 25px;
    }

    &-part {
      &.-primary {
        color: $color-primary;
      }

      &.-secondary {
        color: $color-secondary;
      }

      &.-white {
        color: $color-white;
      }
    }
  }

  &__description {
    color: $color-white;
    font-size: 2.78vmin;
    line-height: 3.70vmin;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5.56vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 24px;
      line-height: 120%;
      margin-bottom: 50px;
      padding: 0 40px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 35px;
      padding: 0 15px;
    }

    &-part {
      &.-primary {
        color: $color-primary;
      }

      &.-secondary {
        color: $color-secondary;
      }

      &.-white {
        color: $color-white;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
  }

  &__button {
    min-width: 31.76vmin;
    padding: 0 4.17vmin;
    font-size: 1.94vmin;

    @media (max-width: $breakpoint-md) {
      min-width: 45%;
      padding: 0 20px;
      font-size: 13px;
    }
    @media (max-width: $breakpoint-sm) {
      min-width: 60%;
    }

    &:not(:last-child) {
      margin-bottom: 1.39vmin;

      @media (max-width: $breakpoint-md) {

      }
    }
  }
}
