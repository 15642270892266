@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.guest-modal {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  display: none;
  z-index: 10000;

  &.react-transition {
    &-enter {
      display: block;
      opacity: 0;
    }
  
    &-enter-active {
      display: block;
      opacity: 1;
      transition: opacity 300ms;
    }

    &-enter-done {
      display: block;
      opacity: 1;
    }
  
    &-exit {
      display: block;
      opacity: 1;
    }
  
    &-exit-active {
      display: block;
      opacity: 0;
      transition: opacity 300ms;
    }

    &-exit-done {
      display: none;
      opacity: 0;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10000;
  }

  &__backdrop {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-white;
    width: 38.42vmin;
    min-height: 25vmin;
    max-height: 85vmin;
    border: 0.09vmin solid $color-primary;
    -webkit-box-shadow: 0 0.28vmin 0.65vmin rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0.28vmin 0.65vmin rgba(0, 0, 0, 0.3);
    box-shadow: 0 0.28vmin 0.65vmin rgba(0, 0, 0, 0.3);

    @media (max-width: $breakpoint-md) {
      width: 70%;
      min-height: 40%;
      max-height: 90%;
      border-width: 1px;
      -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    }
    @media (max-width: $breakpoint-sm) {
      width: 90%;
      min-height: 30%;
      max-height: 90%;
    }
  }

  &__text-content-wrapper {
    padding: 4.35vmin;

    @media (max-width: $breakpoint-md) {
      padding: 45px;
    }

    @media (max-width: $breakpoint-sm) {
      padding: 30px;
    }
  }

  &__title {
    font-size: 3.5vmin;
    line-height: 100%;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2.22vmin;
    text-align: center;

    @media (max-width: $breakpoint-md) {
      font-size: 35px;
      margin-bottom: 35px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 35px;
      margin-bottom: 30px;
    }
  }

  &__body {
    text-align: center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &-text {
      color: $color-black;
      font-size: 1.48vmin;
      line-height: 150%;
      font-weight: 600;

      @media (max-width: $breakpoint-md) {
        font-size: 13px;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 13px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2.31vmin;

    @media (max-width: $breakpoint-md) {
      margin-top: 30px;
    }
  }

  &__button {
    padding: 0 3vmin;
    min-height: 5.2vmin;
    min-width: 20vmin;
    margin-top: 1vmin;

    @media (max-width: $breakpoint-md) {
      width: 70%;
      padding: 0 25px;
      min-height: 8vmin;
    }
    @media (max-width: $breakpoint-sm) {
      width: 60%;
      padding: 0 20px;
      min-height: 9vmin;
    }
  }
}
