@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.candidate-card-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 275px;
  width: 100%;
  border-bottom: 1px solid $color-grey-lightest;
  padding: 25px 0;

  @media (max-width: $breakpoint-sm) {
    height: 250px;
    padding: 20px 0;
  }

  &__top {
    display: flex;
    flex-direction: row;
    height: 164px;
    margin-bottom: 10px;

    @media (max-width: $breakpoint-sm) {
      height: 143px;
      margin-bottom: 5px;
    }
  }

  &__avatar-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 35%;
  }

  &__avatar-section-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 20px;

    @media (max-width: $breakpoint-sm) {
      margin-right: 30px;
    }
  }
  
  &__avatar-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__avatar {
    min-width: 80px;
    width: 100%;
    max-width: 125px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;

    @media (max-width: $breakpoint-sm) {
      max-width: 108px;
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__avatar-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-primary-dark;
    min-width: 80px;
    width: 100%;
    max-width: 145px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;

    @media (max-width: $breakpoint-sm) {
      max-width: 108px;
    }

    &-image {
      height: 45px;

      path {
        fill: $color-white;
      }
    }
  }

  &__languages {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__language {
    display: flex;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    overflow: hidden;
    
    &:not(:last-child) {
      margin-right: 4px;
    }

    &-icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__details-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 65%;
  }

  &__profile-number {
    color: $color-black-light;
    font-size: 17px;
    margin-bottom: 5px;

    @media (max-width: $breakpoint-sm) {
      font-size: 14px;
      margin-bottom: 2px;
    }
  }

  &__profile-title {
    color: $color-primary-dark;
    min-height: 44px;
    font-size: 19px;
    line-height: 120%;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
    margin-bottom: 10px;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media (max-width: $breakpoint-sm) {
      min-height: 39px;
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  &__tools {
    width: 100%;
  }

  &__tool {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 18px;

    @media (max-width: $breakpoint-sm) {
      height: 16px;
    }

    &-name {
      font-size: 14px;
      text-transform: uppercase;
      max-width: calc(100% - 73px);
      margin-right: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: $breakpoint-sm) {
        font-size: 12px;
        max-width: calc(100% - 73px);
        margin-right: 7px;
      }
    }

    &-level-indicator {
      width: 10px;
      min-width: 10px;
      height: 10px;
      min-height: 10px;
      background-color: $color-secondary;
      border: 1px solid $color-secondary;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.-empty {
        background-color: transparent;
      }
    }
  }

  &__skills {
    width: 100%;
  }

  &__skill {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 16px;

    @media (max-width: $breakpoint-sm) {
      
    }

    &-name {
      font-size: 14px;
      text-transform: uppercase;
      max-width: calc(100% - 73px);
      margin-right: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: $breakpoint-sm) {
        font-size: 12px;
        max-width: calc(100% - 73px);
        margin-right: 7px;
      }
    }

    &-level-indicator {
      width: 10px;
      min-width: 10px;
      height: 10px;
      min-height: 10px;
      background-color: $color-secondary;
      border: 1px solid $color-secondary;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.-empty {
        background-color: transparent;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 51px;

    @media (max-width: $breakpoint-sm) {
      height: 40px;
    }
  }

  &__profile-description {
    font-size: 14px;
    margin-right: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;

    @media (max-width: $breakpoint-sm) {
      font-size: 11px;
      margin-right: 18px;
    }
  }

  &__close-button {
    position: absolute;
    top: 5px;
    right: 0;
  }
}
