@import "../../../assets/style/colors";

.candidate-details {
  &__section {
    &-profile,
    &-references,
    &-education,
    &-certifications {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0 15%;

      &:nth-child(even) {
        background-color: $color-primary-lightest;

        .candidate-details__segment-separator {
          border-bottom: 0.09vmin solid $color-white;
        }
      }

      &:nth-child(odd) {
        background-color: $color-white;

        .candidate-details__segment-separator {
          border-bottom: 0.09vmin solid $color-grey-lightest;
        }
      }
    }

    &-skills,
    &-tools {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0 15%;

      &:nth-child(even) {
        background-color: $color-primary-lightest;

        .candidate-details__segment-separator {
          border-bottom: 0.09vmin solid $color-white;
        }
      }

      &:nth-child(odd) {
        background-color: $color-white;

        .candidate-details__segment-separator {
          border-bottom: 0.09vmin solid $color-grey-lightest;
        }
      }
    }

    &-profile {
      padding: 2.57vmin 30.56vmin 3.33vmin;
      text-align: center;
    }

    &-references {
      padding: 5.83vmin 30.56vmin 6.67vmin;
      text-align: center;

      .candidate-details__segment {
        &-title {
          margin-bottom: 4.07vmin;
        }

        &-description {
          &:not(:last-child) {
            margin-bottom: 4.31vmin;
          }
        }

        &-separator {
          margin-bottom: 3.56vmin;
        }
      }
    }

    &-skills {
      padding: 5.65vmin 30.56vmin 4.35vmin;
      text-align: center;

      .candidate-details__segment {
        &-title {
          margin-bottom: 3.80vmin;
        }

        &-skill {
          &:not(:last-child) {
            margin-bottom: 2.59vmin;
          }
        }
  
        &-tool {
          &:not(:last-child) {
            margin-bottom: 2.59vmin;
          }
        }

        &-separator {
          margin-bottom: 2.78vmin;
        }
      }
    }

    &-education {
      padding: 7.13vmin 30.56vmin 5.74vmin;
      text-align: center;

      .candidate-details__segment {
        &-title {
          margin-bottom: 3.15vmin;
        }

        &-description {
          &:not(:last-child) {
            margin-bottom: 4.07vmin;
          }
        }

        &-separator {
          margin-bottom: 4.35vmin;
        }
      }
    }

    &-certifications {
      padding: 8.98vmin 30.56vmin 11.02vmin;
      text-align: center;

      .candidate-details__segment {
        &-title {
          margin-bottom: 5.37vmin;
        }

        &-description {
          &:not(:last-child) {
            margin-bottom: 3.94vmin;
          }
        }

        &-separator {
          margin-bottom: 3.94vmin;
        }
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 8.52vmin 30.56vmin 8.52vmin;
      text-align: center;

      .candidate-details__segment {
        &-title {
          margin-bottom: 4.63vmin;
        }

        &-description {
          margin-bottom: 2.04vmin;
        }
      }
    }
  }

  &__avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3.27vmin;
    position: relative;
  }

  &__avatar {
    width: 23.24vmin;
    height: 23.24vmin;
    border-radius: 50%;
    overflow: hidden;

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__avatar-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-primary-dark;
    width: 23.24vmin;
    height: 23.24vmin;
    border-radius: 50%;
    overflow: hidden;

    &-image {
      height: 13.03vmin;

      path {
        fill: $color-white;
      }
    }
  }

  &__buttons {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(calc(100% + 2.55vmin), -50%);
    display: flex;
    flex-direction: column;
  }

  &__button {
    &:not(:last-child) {
      margin-bottom: 0.93vmin;
    }

    &-my-site {
      margin-bottom: 11.35vmin;
    }

    &-to-top {
      margin-bottom: 2.29vmin;
    }
  }

  &__languages {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2.41vmin;
  }

  &__language {
    display: flex;
    width: 1.61vmin;
    height: 1.61vmin;
    border-radius: 50%;
    overflow: hidden;
    
    &:not(:last-child) {
      margin-right: 0.46vmin;
    }

    &-icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__profile-title {
    color: $color-primary-dark;
    min-height: 4.44vmin;
    font-size: 2.78vmin;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.93vmin;
    word-wrap: break-word;
  }

  &__profile-description {
    color: $color-grey-darker;
    min-height: 5.54vmin;
    font-size: 1.94vmin;
    margin-bottom: 4.26vmin;
  }

  &__profile-link-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__profile-link {
    color: $color-primary-dark;
    font-size: 1.30vmin;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: center;
    transition-duration: 0.3s;

    &:hover {
      cursor: pointer;
      color: $color-secondary;
      transition-duration: 0.3s;
    }
  }

  &__dot-description-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  &__skills-container,
  &__tools-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 0.93vmin;

    &:first-child {
      border-right: 0.09vmin solid $color-white;
    }
  }

  &__segment {
    &-title {
      color: $color-primary-dark;
      font-size: 2.31vmin;
      font-weight: 800;
      text-transform: uppercase;
    }

    &-subtitle {
      color: $color-grey-darker;
      font-size: 1.48vmin;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 0.93vmin;
    }

    &-description {
      color: $color-grey-darker;
      font-size: 1.48vmin;
      font-weight: 500;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 4.31vmin;
      }
    }

    &-separator {
      width: 12.78vmin;
    }

    &-skill,
    &-tool {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 1.76vmin;
      position: relative;

      &-name {
        color: $color-grey-darker;
        font-size: 1.48vmin;
        line-height: 100%;
        font-weight: 700;
        text-transform: uppercase;
        margin-right: 0.93vmin;
      }

      &-level-indicator {
        min-width: 0.93vmin;
        width: 0.93vmin;
        height: 0.93vmin;
        background-color: $color-secondary;
        border: 0.09vmin solid $color-secondary;
        border-radius: 50%;
  
        &:not(:last-child) {
          margin-right: 0.37vmin;
        }
  
        &.-empty {
          background-color: transparent;
        }
      }
    }
  }

  &__case-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 6.85vmin;
  }

  &__case {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-right: 2.13vmin;
    }

    &-image {
      object-fit: cover;
      width: 21.76vmin;
      height: 16.67vmin;
      border-radius: 5.56vmin;
      margin-bottom: 1.30vmin;

      &.-placeholder {
        background: $color-grey-lighter;
      }
    }

    &-name {
      font-size: 1.11vmin;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }
  }

  &__text-to-top {
    color: $color-grey-darker;
    font-size: 1.48vmin;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    transition-duration: 0.3s;

    &:hover {
      cursor: pointer;
      color: $color-secondary;
      transition-duration: 0.3s;
    }
  }
}
