@import '../../../assets/style/colors';

.no-rotate-modal {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 99999;

  &.-visible {
    display: block;
    background: $color-white;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 2vmin;
  }

  &__title {
    font-size: 12vmin;
    line-height: 100%;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 2.22vmin;
    text-align: center;

    &-part {
      line-height: 100%;

      &.-primary {
        color: $color-primary;
      }

      &.-secondary {
        color: $color-secondary;
      }
    }
  }

  &__message {
    color: $color-black;
    font-size: 4vmin;
    line-height: 150%;
    font-weight: 700;
    text-align: center;
  }
}
