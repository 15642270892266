@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/breakpoints";

.button45 {
  color: $color-white;
  background-color: $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.17vmin;
  font-size: 1.20vmin;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 1.57vmin;
  border: none;
  border-radius: 2.87vmin;
  opacity: 1;
  transition-duration: 0.3s;

  @media (max-width: $breakpoint-md) {
    height: 45px;
    font-size: 14px;
    padding: 0 30px;
    border-radius: 30px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.85;
    transition-duration: 0.3s;
  }

  &.-primary {
    background-color: $color-primary;
  }

  &.-primary-light {
    background-color: $color-primary-light;
  }

  &.-primary-dark {
    background-color: $color-primary-dark;
  }

  &.-secondary {
    background-color: $color-secondary;
  }

  &.-black {
    background-color: $color-black;
  }

  &.-red {
    background-color: $color-red;
  }

  &.-red-dark {
    background-color: $color-red-dark;
  }

  &.-bold-label {
    font-weight: 700;
  }

  &.-disabled {
    background-color: $color-grey-light;
    opacity: 0.5;
    pointer-events: none;
  }
}
