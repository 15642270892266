@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.candidates-category-page {
  height: 100vh;

  @media (max-width: $breakpoint-md) {
    height: var(--doc-height);
    padding: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__header {
    flex-shrink: 0;
    background-color: $color-primary-dark;
    height: 18.52vmin;
    width: 100%;
    box-shadow: 0 0.28vmin 0.56vmin #00000029;
  }

  &__title {
    text-align: center;
    margin-top: 7.69vmin;
    font-size: 4.17vmin;
  }

  &__main-category,
  &__slash {
    color: $color-white;
    font-size: 4.17vmin;
    font-weight: 500;
    letter-spacing: 0.21vmin;
    text-transform: uppercase;
  }

  &__subcategory {
    color: $color-secondary;
    font-size: 4.17vmin;
    font-weight: 500;
    letter-spacing: 0.21vmin;
    text-transform: uppercase;
  }

  &__content {
    flex-grow: 1;
    width: 100%;
    padding: 4.72vmin 9.90vmin 1.85vmin;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $breakpoint-lg) {
      padding: 2.78vmin;
    }
    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex: 1 0 auto;
      width: 100%;
      height: calc(100% - 147px - 63px);
      padding: 0 20px;
    }
    @media (max-width: $breakpoint-sm) {
      padding: 0 15px;
    }
  }

  &__footer {
    background-color: $color-grey-lightest;
    height: 14.26vmin;
    width: 100%;
  }

  &__mobile-title {
    display: none;

    @media (max-width: $breakpoint-md) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: $color-white;
      color: $color-primary-dark;
      font-size: 14px;
      line-height: 100%;
      font-weight: 700;
      padding: 0 20px;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      height: 46px;
      border-bottom: 1px solid $color-grey-lightest;
    }
  }

  &__card-mobile-container {
    width: 100%;
    padding-bottom: 60px;
  }

  &__bottom-section {
    display: none;

    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: $color-grey-lightest;
      color: $color-primary-dark;
      font-size: 13px;
      line-height: 100%;
      font-weight: 700;
      text-transform: uppercase;
      height: 60px;
    }
  }

  &__back-button {
    @media (max-width: $breakpoint-md) {
      left: 20px;
      bottom: 15px;
    }
    @media (max-width: $breakpoint-sm) {
      left: 15px;
    }
  }
}