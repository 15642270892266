@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/breakpoints";

.button {
  color: $color-white;
  background-color: $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.65vmin;
  font-size: 1.39vmin;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 2.87vmin;
  opacity: 1;
  transition-duration: 0.3s;

  @media (max-width: $breakpoint-md) {
    height: 50px;
    font-size: 13px;
    border-radius: 30px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.85;
    transition-duration: 0.3s;
  }

  &.-primary {
    background-color: $color-primary;
  }

  &.-primary-dark {
    background-color: $color-primary-dark;
  }

  &.-secondary {
    background-color: $color-secondary;
  }

  &.-disabled {
    background-color: $color-grey-light;
    opacity: 0.5;
    pointer-events: none;
  }
}
