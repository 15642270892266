@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.register-page {
  height: 100vh;
  padding: 0 2.77vmin;

  @media (max-width: $breakpoint-md) {
    padding: 65px 20px 0;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 50px 15px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 9.26vmin;

    @media (max-width: $breakpoint-md) {
      margin-top: 0;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 1.11vmin;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 15px;
    }
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 10px;
    }

    &-front {
      color: $color-primary;
      font-size: 6.67vmin;
      line-height: 8.15vmin;
      font-weight: 900;
      letter-spacing: 0.33vmin;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 60px;
        line-height: 100%;
        letter-spacing: 1px;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 43px;
      }
    }

    &-back {
      color: $color-secondary;
      font-size: 6.67vmin;
      line-height: 8.15vmin;
      font-weight: 900;
      letter-spacing: 0.33vmin;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 60px;
        line-height: 100%;
        letter-spacing: 1px;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 43px;
      }
    }
  }

  &__instruction {
    font-size: 1.94vmin;
    font-weight: 500;
    letter-spacing: 0.19vmin;
    margin-bottom: 3vmin;
    text-align: center;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      letter-spacing: 1px;
      margin-bottom: 25px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
      margin-bottom: 20px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &-input {
      width: 26.94vmin;
      margin-bottom: 1.2vmin;

      @media (max-width: $breakpoint-md) {
        width: 65%;
        margin-bottom: 10px;
      }
      @media (max-width: $breakpoint-sm) {
        width: 80%;
        margin-bottom: 6px;
      }
    }

    &-checkbox-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0.93vmin;
      margin-bottom: 4.35vmin;

      @media (max-width: $breakpoint-md) {
        margin: 10px 0 30px;
      }
      @media (max-width: $breakpoint-sm) {
        margin: 10px 0 20px;
      }
    }

    &-checkbox {
      &-text {
        position: relative;
        top: 1.11vmin;
        font-size: 1.11vmin;
        text-transform: uppercase;

        @media (max-width: $breakpoint-md) {
          top: 14px;
          font-size: 11px;
        }
        @media (max-width: $breakpoint-sm) {
          top: 12px;
        }
      }

      &-link {
        color: $color-primary;
        font-size: 1.11vmin;
        font-weight: 600;
        text-transform: uppercase;
        transition-duration: 0.3s;

        @media (max-width: $breakpoint-md) {
          font-size: 11px;
        }

        &:hover {
          cursor: pointer;
          color: $color-secondary;
          transition-duration: 0.3s;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    min-width: 18.8vmin;

    &.-ok {
      @media (max-width: $breakpoint-md) {
        min-width: 200px;
      }
      @media (max-width: $breakpoint-sm) {
        min-width: 170px;
      }
    }
  
    &.-login {
      @media (max-width: $breakpoint-md) {
        min-width: 190px;
      }
      @media (max-width: $breakpoint-sm) {
        min-width: 160px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.93vmin;

      @media (max-width: $breakpoint-md) {
        margin-bottom: 13px;
      }
    }
  }

  &__resend-verification {
    color: $color-grey;
    font-size: 1.02vmin;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: center;
    margin: 0.93vmin 0 3.43vmin;
    transition-duration: 0.3s;

    &:hover {
      cursor: pointer;
      color: $color-secondary;
      transition-duration: 0.3s;
    }

    @media (max-width: $breakpoint-md) {
      font-size: 11px;
      margin-top: 20px;
    }
  }

  &__descriptions {
    text-align: center;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 100px;
    }
  }

  &__description {
    font-size: 1.85vmin;
    line-height: 3.43vmin;
    font-weight: 500;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      line-height: 180%;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
    }
  }
}
