@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.login-page {
  height: 100vh;
  padding: 0 2.77vmin;

  @media (max-width: $breakpoint-md) {
    padding: 65px 20px 0;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 50px 15px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 6vmin;

    @media (max-width: $breakpoint-md) {
      margin-top: 0;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__welcome {
    font-size: 3.7vmin;
    line-height: 4.54vmin;
    font-weight: 300;
    letter-spacing: 0.19vmin;
    margin-bottom: 2vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 26px;
      line-height: 120%;
      letter-spacing: 2px;
      margin-bottom: 22px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 21px;
      margin-bottom: 20px;
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 3vmin;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 30px;
    }
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 26px;
    }

    &-front {
      color: $color-secondary;
      font-size: 12.95vmin;
      line-height: 100%;
      font-weight: 900;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 60px;
        letter-spacing: 1px;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 43px;
      }
    }

    &-back {
      color: $color-primary;
      font-size: 12.95vmin;
      line-height: 100%;
      font-weight: 900;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 55px;
        letter-spacing: 1px;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 39px;
      }
    }
  }

  &__descriptions {
    text-align: center;
    margin-bottom: 2vmin;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 25px;
    }
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 20px;
    }
  }

  &__description {
    font-size: 1.85vmin;
    line-height: 3.43vmin;
    font-weight: 500;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      line-height: 120%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &-input {
      width: 26.94vmin;
      margin-bottom: 1.2vmin;

      @media (max-width: $breakpoint-md) {
        width: 65%;
        margin-bottom: 10px;
      }
      @media (max-width: $breakpoint-sm) {
        width: 80%;
        margin-bottom: 6px;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vmin;

    @media (max-width: $breakpoint-md) {
      margin-top: 24px;
    }
    @media (max-width: $breakpoint-sm) {
      margin-top: 20px;
    }
  }

  &__button {
    min-width: 18.8vmin;

    &.-login {
      @media (max-width: $breakpoint-md) {
        min-width: 200px;
      }
      @media (max-width: $breakpoint-sm) {
        min-width: 170px;
      }
    }
  
    &.-register {
      @media (max-width: $breakpoint-md) {
        min-width: 190px;
      }
      @media (max-width: $breakpoint-sm) {
        min-width: 160px;
      }
    }

    &.-guest-login {
      margin-top: 5vmin;

      @media (max-width: $breakpoint-md) {
        min-width: 200px;
        min-height: 45px;
        margin-bottom: 120px;
      }
      @media (max-width: $breakpoint-sm) {
        min-width: 170px;
        min-height: 45px;
        margin-bottom: 120px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.93vmin;

      @media (max-width: $breakpoint-md) {
        margin-bottom: 13px;
      }
    }
  }

  &__forgot-password,
  &__resend-verification {
    color: $color-grey;
    font-size: 1.02vmin;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: center;
    margin-top: 2vmin;
    transition-duration: 0.3s;

    @media (max-width: $breakpoint-md) {
      font-size: 11px;
      margin-top: 20px;
    }

    &:hover {
      cursor: pointer;
      color: $color-secondary;
      transition-duration: 0.3s;
    }
  }

  &__resend-verification {
    margin-top: 0.93vmin;

    @media (max-width: $breakpoint-md) {
      margin: 8px 0 0;
    }
  }
}
