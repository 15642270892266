@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.register-success-page {
  height: 100vh;

  @media (max-width: $breakpoint-md) {
    padding: 50px 20px 0;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 50px 15px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2.04vmin;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 30px;
    }
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 25px;
    }

    &-front,
    &-back {
      color: $color-primary;
      font-size: 4.35vmin;
      font-weight: 900;
      letter-spacing: 0.33vmin;
      text-transform: uppercase;
      word-wrap: nowrap;

      @media (max-width: $breakpoint-md) {
        font-size: 35px;
        letter-spacing: 2px;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 20px;
        letter-spacing: 1px;
      }
    }

    &-center {
      color: $color-secondary;
      font-size: 4.35vmin;
      font-weight: 900;
      letter-spacing: 0.33vmin;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 35px;
        letter-spacing: 2px;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 20px;
        letter-spacing: 1px;
      }
    }
  }

  &__descriptions {
    text-align: center;
    margin-bottom: 3.89vmin;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 30px;
    }
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 20px;
    }
  }

  &__description {
    font-size: 1.85vmin;
    line-height: 3.43vmin;
    font-weight: 500;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      line-height: 180%;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
    }
  }

  &__button {
    min-width: 18.8vmin;

    @media (max-width: $breakpoint-md) {
      min-width: 200px;
    }
  }
}
