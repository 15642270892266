@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.candidate-details-mobile {
  &__section {
    &-profile,
    &-references,
    &-education,
    &-certifications {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0 40px;

      @media (max-width: $breakpoint-sm) {
        padding: 0 15px;
      }

      &:nth-child(even) {
        background-color: $color-primary-lightest;

        .candidate-details-mobile__segment-separator {
          border-bottom: 0.09vmin solid $color-white;
        }
      }

      &:nth-child(odd) {
        background-color: $color-white;

        .candidate-details-mobile__segment-separator {
          border-bottom: 0.09vmin solid $color-grey-lightest;
        }
      }
    }

    &-skills,
    &-tools {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 40px;

      @media (max-width: $breakpoint-sm) {
        padding: 30px 15px;
      }

      &:nth-child(even) {
        background-color: $color-primary-lightest;

        .candidate-details-mobile__segment-separator {
          border-bottom: 1px solid $color-white;
        }
      }

      &:nth-child(odd) {
        background-color: $color-white;

        .candidate-details-mobile__segment-separator {
          border-bottom: 1px solid $color-grey-lightest;
        }
      }
    }

    &-profile {
      position: relative;
      margin-top: 25px;
    }

    &-references,
    &-education,
    &-certifications {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding-top: 50px;
      padding-bottom: 70px;
      border: 1px solid $color-grey-lightest;
      text-align: center;

      .candidate-details-mobile__segment {
        &-title {
          margin-bottom: 4.63vmin;
        }

        &-description {
          margin-bottom: 2.04vmin;
        }
      }
    }
  }

  &__profile-number {
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
  }

  &__avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }

  &__avatar {
    width: 163px;
    min-width: 163px;
    height: 163px;
    min-height: 163px;
    border-radius: 50%;
    overflow: hidden;

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__avatar-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-primary-dark;
    width: 163px;
    min-width: 163px;
    height: 163px;
    min-height: 163px;
    border-radius: 50%;
    overflow: hidden;

    &-image {
      height: 91px;

      path {
        fill: $color-white;
      }
    }
  }

  &__languages {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px;
  }

  &__language {
    display: flex;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    overflow: hidden;
    
    &:not(:last-child) {
      margin-right: 4px;
    }

    &-icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__profile-title {
    color: $color-primary-dark;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px;
    word-wrap: break-word;
  }

  &__profile-description {
    color: $color-grey-darker;
    font-size: 13px;
    line-height: 150%;
    text-align: center;
    margin-bottom: 15px;
  }

  &__profile-link-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 25px;
  }

  &__profile-link {
    color: $color-primary-dark;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: center;
    transition-duration: 0.3s;

    &:hover {
      cursor: pointer;
      color: $color-secondary;
      transition-duration: 0.3s;
    }
  }

  &__dot-description-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }

  &__profile-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  &__profile-button {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px
    }
  }

  &__segment {
    &-title {
      color: $color-primary-dark;
      font-size: 15px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    &-subtitle {
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 6px;
    }

    &-description {
      font-size: 13px;
      font-weight: 400;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    &-separator {
      width: 100%;
      margin-bottom: 20px;
    }

    &-skill,
    &-tool {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 100%;
      height: 16px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 13px;
      }

      &-name {
        color: $color-grey-darker;
        font-size: 13px;
        line-height: 100%;
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 10px;
      }

      &-level-indicator {
        min-width: 10px;
        width: 10px;
        height: 10px;
        min-height: 10px;
        background-color: $color-secondary;
        border: 1px solid $color-secondary;
        border-radius: 50%;
  
        &:not(:last-child) {
          margin-right: 4px;
        }
  
        &.-empty {
          background-color: transparent;
        }
      }
    }
  }

  &__skills-container,
  &__tools-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &:first-child {
      padding-bottom: 20px;
      border-bottom: 1px solid $color-white;
    }

    &:last-child {
      padding-top: 20px;
    }
  }

  &-references,
  &-education,
  &-certifications {
    text-align: center;

    .candidate-details-mobile__segment {
      &-title {
        margin-bottom: 17px;
      }
    }
  }

  &__button-my-site {
    margin-bottom: 11.35vmin;
  }
}
