@import '../../../../assets/style/colors';
@import '../../../../assets/style/breakpoints';

.profile-page-desktop {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  &__menu {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background-color: $color-grey-lightest;
    width: 28%;
    max-width: 49.54vmin;
    padding: 18.52vmin 14.81vmin 0 10.19vmin;

    @media (max-width: $breakpoint-xxxl) {
      width: 24%;
      padding: 18.52vmin 5vmin 0 4vmin;
    }
  }

  &__menu-title {
    color: $color-primary-dark;
    font-size: 3.70vmin;
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: 1.39vmin;
  }

  &__detail {
    font-size: 1.39vmin;
    line-height: 2.31vmin;
  }

  &__edit {
    color: $color-grey-darker;
    font-size: 0.74vmin;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 1.39vmin;
    transition-duration: 0.2s;

    &:hover {
      cursor: pointer;
      color: $color-grey;
      transition-duration: 0.2s;
    }
  }

  &__button {
    font-size: 0.93vmin;
    font-weight: 500;
    width: 10.19vmin;
    margin-bottom: 3.29vmin;
  }

  &__option {
    color: $color-primary-dark;
    font-size: 1.67vmin;
    font-weight: 500;
    text-transform: uppercase;
    padding-top: 2.13vmin;
    border-top: 0.09vmin solid $color-primary-dark;
    margin-bottom: 2.13vmin;
    transition-duration: 0.2s;

    &:hover {
      cursor: pointer;
      color: $color-secondary;
      transition-duration: 0.2s;
    }

    &.-active {
      font-weight: 900;

      &:hover {
        color: $color-primary-dark;
      }
    }
  }

  &__content {
    flex-grow: 1;
    min-width: 72%;
    max-width: 128.24vmin;
    padding: 12.69vmin 24.44vmin 0 14.07vmin;
    margin-bottom: 9.26vmin;

    @media (max-width: $breakpoint-xxxl) {
      min-width: 76%;
      padding: 12.69vmin 4vmin 0 5vmin;
    }
  }

  &__content-title {
    font-size: 5.56vmin;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 2.04vmin;
  }
}
