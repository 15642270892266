@import '../../../../../assets/style/colors';

.case-page-mobile-case {
  &__gallery {
    width: 100%;
    aspect-ratio: 390/210;
  }

  &__slider {
    height: 100%;

    .slick-list {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }

    .slick-slide {
      height: 100%;

      div {
        height: 100%;
      }
    }
  }

  &__image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    width: 100%;
    padding: 18px 15px;
  }

  &__title {
    color: $color-primary-dark;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 17px;
  }

  &__description {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 17px;
  }

  &__button-candidates {
    margin-bottom: 50px;
  }
}
