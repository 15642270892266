@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.top-menu-layer-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: $color-primary;
  width: 100%;
  height: 100px;
  padding: 50px 20px 15px;

  @media (max-width: $breakpoint-sm) {
    padding: 50px 15px 15px;
  }

  &__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    width: 33px;
    border: 1px solid $color-grey-light;
    border-radius: 50%;
    overflow: hidden;
    transition-duration: 0.3s;

    &:hover {
      cursor: pointer;
      border: 1px solid $color-grey-lightest;
      background-color: $color-grey-light;
      transition-duration: 0.3s;

      .top-menu-layer__button {
        path {
          fill: $color-grey-lightest;
          transition-duration: 0.3s;
        }
      }
    }
  }

  &__button {
    font-size: 15px;

    path {
      fill: $color-grey-light;
      transition-duration: 0.3s;
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    line-height: 100%;

    &-front {
      color: $color-secondary;
      font-size: 30px;
      line-height: 100%;
      font-weight: 900;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &-back {
      color: $color-white;
      font-size: 28px;
      line-height: 100%;
      font-weight: 900;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}