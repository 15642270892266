@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.case-page-mobile {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: $color-white;
    color: $color-primary-dark;
    font-size: 14px;
    line-height: 100%;
    font-weight: 700;
    padding: 0 20px;
    text-transform: uppercase;
    text-align: center;
    height: 46px;
    border-bottom: 1px solid $color-grey-lightest;
  }

  &__submenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    background: $color-white;
    color: $color-primary-dark;
    font-size: 14px;
    line-height: 100%;
    font-weight: 700;
    padding: 0 20px;
    text-transform: uppercase;
    text-align: center;
    height: 60px;
    border-bottom: 1px solid $color-grey-lightest;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  
    &::-webkit-scrollbar {
      display: none;
    }

    &-button {
      padding: 0 15px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &__subpage {
    flex-grow: 1;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__bottom-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: $color-grey-lightest;
    color: $color-primary-dark;
    font-size: 13px;
    line-height: 100%;
    font-weight: 700;
    text-transform: uppercase;
    height: 60px;
  }

  &__back-button {
    @media (max-width: $breakpoint-md) {
      left: 20px;
      bottom: 15px;
    }
    @media (max-width: $breakpoint-sm) {
      left: 15px;
    }
  }
}
