@import '../../../../assets/style/colors';
@import '../../../../assets/style/breakpoints';

.profile-page-mobile {
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: $breakpoint-md) {
    height: var(--doc-height);
    padding: 0;
  }

  &__content {
    padding: 44px 109px 40px 15px;
    flex-grow: 1;
    height: calc(100% - 100px - 60px - 46px);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    color: $color-primary-dark;
    font-size: 40px;
    line-height: 74px;
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: 15px;
  }

  &__detail {
    font-size: 15px;
    line-height: 26px;
  }

  &__edit {
    color: $color-grey-darker;
    font-size: 8px;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 15px;
    transition-duration: 0.2s;

    &:hover {
      cursor: pointer;
      color: $color-grey;
      transition-duration: 0.2s;
    }
  }

  &__button {
    font-size: 10px;
    font-weight: 500;
    width: 110px;
    margin-bottom: 35px;
  }

  &__options {
    margin-bottom: 50px;
  }

  &__option {
    color: $color-primary-dark;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding-top: 23px;
    border-top: 1px solid $color-primary-dark;
    margin-bottom: 23px;
    transition-duration: 0.2s;

    &:hover {
      cursor: pointer;
      color: $color-secondary;
      transition-duration: 0.2s;
    }

    &.-active {
      font-weight: 900;

      &:hover {
        color: $color-primary-dark;
      }
    }
  }

  &__mobile-title {
    display: none;
    
    @media (max-width: $breakpoint-md) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      height: 100px;
      background: $color-white;
      color: $color-primary-dark;
      font-size: 14px;
      line-height: 100%;
      font-weight: 700;
      padding: 0 20px;
      text-transform: uppercase;
      text-align: center;
      height: 46px;
      border-bottom: 1px solid $color-grey-lightest;
    }
  }

  &__bottom-section {
    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: $color-grey-lightest;
      color: $color-primary-dark;
      font-size: 13px;
      line-height: 100%;
      font-weight: 700;
      text-transform: uppercase;
      height: 60px;
    }
  }

  &__back-button {
    @media (max-width: $breakpoint-md) {
      left: 20px;
      bottom: 15px;
    }
    @media (max-width: $breakpoint-sm) {
      left: 15px;
    }
  }
}
